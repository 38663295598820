<template>
  <div class="service-panel">
    <Row v-if="this.power.indexOf('服务管理--提交服务纪录权限') > -1">
      <Col span="16">
        <div class="card">
          <div class="card-title">本次服务记录</div>
          <div class="card-body">
            <div class="flex-panel mb20">
              <div class="flex-panel" style="width: 40%;">
                <span class="flex-label">主诉</span>
                <Input
                  :value="memberDetail.main_xx"
                  type="textarea"
                  :rows="1"
                  style="flex: 1;margin-right: 10px;"
                  readonly
                  resize="none"
                ></Input>
                <Button type="warning" @click="showModal('添加主诉')">新增</Button>
              </div>
              <div class="flex-panel" style="flex: 1;margin-left: 20px;">
                <span class="flex-label">产品服用情况</span>
                <Input
                  :value="memberDetail.cpfyqk"
                  type="textarea"
                  :rows="1"
                  style="flex: 1;margin-right: 10px;"
                  readonly
                  resize="none"
                ></Input>
                <Button type="warning" @click="showModal('添加产品服用情况')">新增</Button>
              </div>
            </div>
            <div class="mb20">
              <span class="flex-label">咨询</span>
              <Select v-model="data.zxlx" placeholder="请选择" style="width:150px">
                <Option value="报告咨询">报告咨询</Option>
                <Option value="ECP方案咨询">ECP方案咨询</Option>
                <Option value="体糖服务">体糖服务</Option>
              </Select>
              <template v-if="data.zxlx === '报告咨询'">
                <span class="flex-label" style="margin-left: 30px;">报告名称</span>
                <Select v-model="bgmc" filterable placeholder="请选择" style="width:250px">
                  <Option
                    v-for="item in reportNameList"
                  :value="item.name"
                    :key="item.id"
                  >{{ item.name }}({{item.num}})</Option>
                </Select>
              </template>
              <template v-if="data.zxlx === 'ECP方案咨询'">
                <span class="flex-label" style="margin-left: 30px;">咨询类型</span>
                <Select v-model="data.zxzl" placeholder="请选择" style="width:250px">
                  <Option value="专家咨询">专家咨询</Option>
                  <Option value="教练咨询">教练咨询</Option>
                </Select>
                <Button
                  type="warning"
                  style="margin-left: 20px;"
                  v-if="data.zxzl === '专家咨询'"
                  @click="showPlanModal"
                >一日标准健康计划</Button>
              </template>
            </div>
            <div class="flex-panel mb20">
              <div style="width: 40%;">
                <div style="margin-bottom:10px;">
                  <span class="flex-label">生理数值对比情况</span>
                  <CheckboxGroup
                    v-model="slszdbqk"
                    style="display: inline-block;"
                    @on-change="handleSelectChange('对比情况')"
                  >
                    <Checkbox label="✓">数值改善</Checkbox>
                    <Checkbox label="✕">需调整</Checkbox>
                  </CheckboxGroup>
                </div>
                <Input
                  v-model="item.content"
                  placeholder="请输入顾客数据变化或需调整的指标"
                  v-for="(item, index) in valueData"
                  :key="index"
                  class="mb5"
                ></Input>
              </div>
              <div style="flex: 1;margin-left: 20px;">
                <div class="flex-panel">
                  <span class="flex-label">感受变化</span>
                  <Input
                    v-model="data.gsbh"
                    type="textarea"
                    :rows="3"
                    placeholder="请输入顾客感受变化"
                    style="flex: 1;"
                  ></Input>
                </div>
              </div>
            </div>
            <div class="flex-panel mb5">
              <span class="flex-label">方案措施</span>
              <Select
                v-model="facslx"
                @on-change="handleSelectChange('方案措施')"
                multiple
                filterable
                style="flex: 1;"
              >
                <Option value="循律方案指导">循律方案指导</Option>
                <Option value="靶向方案指导">靶向方案指导</Option>
                <Option value="再生方案指导">再生方案指导</Option>
                <OptionGroup label="重点检测">
                  <Option
                    v-for="item in reportNameList"
                    :value="item.name"
                    :key="item.id"
                  >{{ item.name }}</Option>
                </OptionGroup>
              </Select>
            </div>
            <Input
              v-model="item.content"
              type="textarea"
              autosize
              placeholder="请输入方案措施内容"
              v-for="(item, index) in planData"
              :key="index"
              class="mb5"
            ></Input>
          </div>
        </div>
      </Col>
      <Col span="8">
        <div class="card">
          <div class="card-title">咨询结果描述</div>
          <div class="card-body">
            <div class="flex-panel">
              <span class="flex-label">咨询结果</span>
              <div style="flex: 1;">
                <Select
                  v-model="zxjglx"
                  multiple
                  @on-change="() => handleSelectChange('咨询结果')"
                  style="margin-bottom:10px;"
                >
                  <Option value="靶向产品预热">靶向产品预热</Option>
                  <Option value="靶向产品销售">靶向产品销售</Option>
                  <Option value="卡系消耗">卡系消耗</Option>
                </Select>
                <Input
                  v-model="item.content"
                  placeholder="请输入资讯结果"
                  v-for="(item, index) in resultData"
                  :key="index"
                  class="mb5"
                ></Input>
              </div>
            </div>
            <div class="flex-panel">
              <span class="flex-label">联系员工</span>
              <div style="flex: 1;">
                <Select v-model="data.lxyg_id" filterable style="margin-bottom:10px;">
                  <Option
                    v-for="item in staffList"
                    :key="item.id"
                    :value="item.id"
                  >{{ item.name }}</Option>
                </Select>
              </div>
            </div>
            <div>
              <span class="flex-label">下次预约时间</span>
              <DatePicker
                type="date"
                :value="data.xcyysj"
                @on-change="handleDateChange"
                placeholder="请选择预约时间"
                style="width: 170px"
              ></DatePicker>
            </div>
          </div>
        </div>
        <div style="text-align:right;margin-right:20px;">
          <Button type="primary" @click="submit">完成咨询</Button>
        </div>
      </Col>
    </Row>
    <Table :columns="columns" :data="recordList" class="record-list"></Table>
    <p class="list-total">共{{ totalItems }}条记录</p>
    <div class="page-nations-wrapper">
      <div class="page-nations">
        <Page
          :total="totalItems"
          :current="searchData.page"
          :page-size="searchData.size"
          @on-change="handleChangePage"
        ></Page>
      </div>
    </div>
    <Modal :title="title" v-model="sickModal">
      <Form :label-width="155" v-if="title === '添加主诉'">
        <Form-item label="发现时间：">
          <Date-picker type="date" placeholder="选择主诉时间" @on-change="changeDate"></Date-picker>
        </Form-item>
        <Form-item label="身体最近有哪些不适：">
          <Input type="textarea" v-model="sick.describe" :maxlength="120" placeholder="请输入..."></Input>
          <p class="text">注：最多只能输入120字</p>
        </Form-item>
      </Form>
      <Form :label-width="110" v-else>
        <Form-item label="类型：">
          <Select
           placeholder="请选择"
           clearable
           v-model="drug.drugType"
           @on-change="handleDrugTypeChange"
          >
            <Option v-for="item in typeList" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
        </Form-item>
        <Form-item label="名称：">
          <Select filterable clearable placeholder="请选择" v-model="drug.type_id">
            <Option v-for="item in drugList" :value="item.value" :key="item.id">{{ item.label }}</Option>
          </Select>
        </Form-item>
        <Form-item label="使用方法：">
          <Input v-model="drug.way" type="textarea" placeholder="举例：一日3次，早2粒中1粒晚1粒..." :maxlength="100"></Input>
          <p class="drug-text">注：最多只能输入100字</p>
        </Form-item>
        <Form-item label="使用开始时间：">
          <Date-picker type="date" placeholder="选择日期" @on-change="changeDrugTime"></Date-picker>
        </Form-item>
        <Form-item label="服用依从性：">
          <Select placeholder="请选择" v-model="drug.compliance_id">
            <Option v-for="item in complianceData" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
        </Form-item>
        <Form-item label="采购地点：">
          <Input placeholder="请输入采购地点..." type="textarea" v-model="drug.place" :maxlength="100"></Input>
          <p class="drug-text">注：最多只能输入100字</p>
        </Form-item>
        <Form-item label="寄存数量：">
          <Input placeholder="请输入寄存数量..." type="text" v-model="drug.num"></Input>
        </Form-item>
      </Form>
      <div slot="footer">
        <div class="symbol-box tac">
          <Button @click="hideModal">取消</Button>
          <Button type="warning" @click="saveSick" v-if="title === '添加主诉'">保存</Button>
          <Button type="warning" @click="saveDrug" v-else>保存</Button>
        </div>
      </div>
    </Modal>
    <Modal title="一日标准健康计划修改" v-model="planModal" width="1100px">
      <template v-if="!showPDF">
        <div class="plan-history">
          <span class="plan-history-title">历史计划</span>
          <div
            class="plan-history-content"
            v-for="(item, i) in planHistories"
            :key="i"
            @click="getHistoryDetail(item)"
          >
            {{ item }}
          </div>
        </div>
        <table class="plan-table">
          <tbody>
            <tr>
              <th>时间段</th>
              <th>类型</th>
              <th>名称</th>
              <th>使用方法</th>
              <th>备注</th>
              <th style="width: 80px;">操作</th>
            </tr>
            <tr v-for="(item, i) in historyData" :key="i">
              <td>
                <Select v-model="item.sjd">
                  <Option value="晨起">晨起</Option>
                  <Option value="早餐前">早餐前</Option>
                  <Option value="早餐">早餐</Option>
                  <Option value="早餐后">早餐后</Option>
                  <Option value="上午">上午</Option>
                  <Option value="上加餐">上加餐</Option>
                  <Option value="午餐前">午餐前</Option>
                  <Option value="午餐">午餐</Option>
                  <Option value="午餐后">午餐后</Option>
                  <Option value="下午">下午</Option>
                  <Option value="下加餐">下加餐</Option>
                  <Option value="晚餐前">晚餐前</Option>
                  <Option value="晚餐">晚餐</Option>
                  <Option value="晚餐后">晚餐后</Option>
                  <Option value="睡前">睡前</Option>
                </Select>
              </td>
              <td>
                <Select v-model="item.lx" @on-change="handleTypeChange($event, item)">
                  <Option v-for="type in planTypes" :key="type.id" :value="type.name">
                    {{ type.name }}
                  </Option>
                </Select>
              </td>
              <td>
                <Select v-model="item.mc" @on-change="handleNameChange($event, item)">
                  <Option 
                    v-for="name in item.nameOptions"
                    :key="name.name"
                    :value="name.name"
                  >
                    {{ name.name }}
                  </Option>
                </Select>
              </td>
              <td>
                <Select @on-change="handleUseChange($event, item)" style="width: 40%;">
                  <Option
                    v-for="method in item.methodOptions"
                    :key="method.name"
                    :value="method.name"
                  >
                    {{ method.name }}
                  </Option>
                </Select>
                <Input v-model="item.syff" style="width: 60%;" />
              </td>
              <td>
                <Input v-model="item.bz" />
              </td>
              <td style="text-align: center;vertical-align: middle;">
                <Icon type="md-add" size="22" @click="addPlanRow(i)" />
                <Icon type="md-remove" size="22" @click="removePlanRow(i)" />
              </td>
            </tr>
          </tbody>
        </table>
      </template>
      <div class="pdf-panel" v-else>
        <div class="pdf-title">一日标准健康计划</div>
        <div class="pdf-profile">
          <span>门店：{{ memberDetail.ssmd }}</span>
          <span>姓名：{{ memberDetail.name }}</span>
          <span>性别：{{ memberDetail.sex === 0 ? '男' : '女' }}</span>
          <span>年龄：{{ memberDetail.age }}</span>
        </div>
        <div class="pdf-desc">
          标准健康来源于每一天的生活方式趋向标准。基于适生技术，盛世康和通过现阶段健康状况综合评估分析，得出您的个性化一日标准健康计划。该计划有效期为90天，请在90天内咨询专家根据新的健康状况进行调整。
        </div>
        <table class="pdf-table">
          <tbody>
            <tr>
              <th style="width: 80px;">时间段</th>
              <th style="width: 100px;">类型</th>
              <th style="width: 220px;">名称</th>
              <th>使用方法</th>
              <th style="width: 300px;">备注</th>
            </tr>
            <tr v-for="(item, i) in historyData" :key="i">
              <td>{{ item.sjd }}</td>
              <td>{{ item.lx }}</td>
              <td>{{ item.mc }}</td>
              <td>{{ item.syff }}</td>
              <td>{{ item.bz }}</td>
            </tr>
          </tbody>
        </table>
        <div class="pdf-subtitle">方案措施</div>
        <div class="pdf-content" v-html="replaceBreak(pdfPlanData)"></div>
        <div class="pdf-footer">
          <span>日期：{{ today }}</span>
          <span>方案指导：{{ loginUser.name }}</span>
        </div>
      </div>
      <div slot="footer">
        <div class="symbol-box tac">
          <Button @click="planModal = false">取消</Button>
          <template v-if="!showPDF">
            <Button type="warning" @click="generatePDF" v-if="pdfPlanData">生成 PDF</Button>
            <Button type="warning" @click="savePlan">保存记录</Button>
          </template>
          <Button type="warning" @click="downloadPDF" v-else>下载 PDF</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import serviceRecordService from '@/services/serviceRecordService';
import memberService from '@/services/memberService';

export default {
	data() {
		return {
			data: {
				zxlx: '报告咨询',
				zxzl: '专家咨询',
				slszdbqk: '',
				dbqkxq: '',
				gsbh: '',
				facslx: '',
				facsnr: '',
				zxjglx: '',
				zxjgnr: '',
				xcyysj: '',
				lxyg_id: '',
			},
			bgmc: '',
			slszdbqk: [],
			facslx: [],
			zxjglx: [],
			power: '',
			reportNameList: [],
			columns: [
				{ title: '日期', key: 'rq', align: 'center', width: 150 },
				{ title: '服务员工', key: 'staff', align: 'center', width: 150 },
				{ title: '咨询', key: 'zx', align: 'center' },
				{ title: '报告名称', key: 'bgmc', align: 'center', width: 150 },
				{ title: '生理数值对比情况', key: 'slszdbqk', align: 'center' },
				{ title: '感受变化', key: 'gsbh', align: 'center' },
				{ title: '方案措施', key: 'facs', align: 'center', tooltip: true },
				{ title: '咨询结果', key: 'zxjg', align: 'center', tooltip: true },
				{ title: '下次预约时间', key: 'xcyysj', align: 'center' },
			],
			recordList: [],
			searchData: {
				page: 1,
				size: 10,
			},
			totalItems: 0,
			valueData: [],
			planData: [],
			resultData: [],
			staffList: [],
			memberDetail: {},
			memberId: '',
			title: '添加主诉',
			sickModal: false,
			sick: {
				describe: '',
				find_time: '',
			},
			drug: {},
			allData: [],
			drugList: [],
			typeList: [],
			complianceData: [],
			planModal: false,
			planTypes: [],
			planDetail: [],
			planHistories: [],
			historyData: [],
			pdfPlanData: '',
			showPDF: false,
			loginUser: {},
			today: dayjs().format('YYYY-MM-DD'),
		};
	},
	created() {
		this.loginUser = JSON.parse(localStorage.getItem('userInfo')).user_info;
		this.power = localStorage.getItem('userPower');
		this.memberId = this.$route.params.member_id;
		this.data.xcyysj = dayjs().add(28, 'day').format('YYYY-MM-DD');
		this.getRecordList();
		this.getReportName();
		this.getStaffs();
		this.getMemberDetail();
		if (this.$route.query.type === 'blood') this.data.zxlx = '体糖服务';
		memberService.listDrugName().then((data) => {
			this.allData = data;
		});
		memberService.listDrugType().then((data) => {
			this.typeList = data;
		});
		memberService.complianceList().then((data) => {
			this.complianceData = data;
		});
		serviceRecordService.getDayPlanTypes().then((data) => {
			this.planTypes = data.lx;
			this.planDetail = data.mc.map((item) => {
				item.typeName = data.lx.find((type) => type.id === item.lx_id).name;
				return item;
			});
		});
		serviceRecordService.getDayPlanDefault().then((data) => {
			this.historyData = JSON.parse(data);
		});
	},
	methods: {
		submit() {
			this.data['slszdbqk'] = this.slszdbqk.join('、');
			this.data['facslx'] = this.facslx.join('、');
			this.data['zxjglx'] = this.zxjglx.join('、');
			this.data['dbqkxq'] = this.valueData.map((i) => i.content).join('\n');
			this.data['facsnr'] = this.planData.map((i) => i.content).join('\n');
			this.data['zxjgnr'] = this.resultData.map((i) => i.content).join('\n');
			// if (Object.values(this.data).some(item => !item)) {
			//   this.$Message.error('请将数据补充完整')
			//   return
			// }
			if (this.data.zxlx === '报告咨询') {
				if (!this.bgmc) {
					delete this.data.bgmc;
					this.$Message.error('请将数据补充完整');
					return;
				} else {
					this.data.bgmc = this.bgmc;
				}
			} else {
				this.bgmc = '';
			}
			this.data['member_id'] = this.memberId;
			serviceRecordService.addAdvisory(this.data).then(() => {
				this.getRecordList();
			});
		},
		getReportName() {
			serviceRecordService.getAdvisoryReportName().then((data) => {
				this.reportNameList = data;
			});
		},
		handleDateChange(val) {
			this.data.xcyysj = val;
		},
		handleSelectChange(type) {
			switch (type) {
				case '对比情况':
					this.valueData = this.generateContent(this.slszdbqk, this.valueData);
					break;
				case '方案措施':
					this.planData = this.generateContent(this.facslx, this.planData);
					break;
				case '咨询结果':
					this.resultData = this.generateContent(this.zxjglx, this.resultData);
					break;
			}
		},
		generateContent(selected, content) {
			const result = [];
			selected.forEach((item) => {
				const index = content.findIndex((i) => i.title === item);
				if (index === -1) {
					result.push({ title: item, content: `${item}：` });
				} else {
					result.push(content[index]);
				}
			});
			return result;
		},
		getRecordList() {
			this.searchData['member_id'] = this.memberId;
			serviceRecordService.getAdvisoryList(this.searchData).then((data) => {
				this.recordList = data.list;
				this.totalItems = data.row_size;
			});
		},
		handleChangePage(page) {
			this.searchData.page = page;
			this.getRecordList();
		},
		getStaffs() {
			memberService.getServiceStaffList().then((data) => {
				this.staffList = data;
			});
		},
		getMemberDetail() {
			memberService.getinfo({ member_id: this.memberId }).then((data) => {
				this.memberDetail = data;
			});
		},
		changeDate(value) {
			this.sick.find_time = value;
		},
		changeDrugTime(date) {
			this.drug.start_time = date;
		},
		saveSick() {
			this.sick.member_id = this.memberId;
			memberService.mainAdd(this.sick).then(() => {
				this.hideModal();
				this.$Message.success('保存成功');
				this.getMemberDetail();
			});
		},
		saveDrug() {
			this.drug.member_id = this.memberId;
			memberService.addDrug(this.drug).then(() => {
				this.hideModal();
				this.$Message.success('保存成功');
				this.getMemberDetail();
			});
		},
		showModal(title) {
			this.title = title;
			this.sickModal = true;
		},
		hideModal() {
			this.sick = {};
			this.drug = {};
			this.sickModal = false;
		},
		handleDrugTypeChange() {
			this.drug.type_id = '';
			const res = this.allData.find((item) => item.value === this.drug.drugType);
			if (res) {
				this.drugList = res.children;
			}
		},
		getHistories() {
			serviceRecordService.getDayPlanHistories({ member_id: this.memberId }).then((data) => {
				this.planHistories = data;
			});
		},
		getHistoryDetail(day) {
			serviceRecordService.getDayPlan({ member_id: this.memberId, day }).then((data) => {
				this.historyData = JSON.parse(data);
			});
		},
		showPlanModal() {
			this.getHistories();
			this.planModal = true;
			this.showPDF = false;
			this.getPDFPlanData();
			// this.historyData = [{
			//   sjd: '',
			//   lx: '',
			//   mc: '',
			//   syff: '',
			//   bz: ''
			// }]
		},
		hidePlanModal() {
			this.planModal = false;
		},
		handleUseChange(val, item) {
			item.syff = val;
		},
		downloadPDF() {
			const loading = this.$loading({
				lock: true,
				text: '正在存储中，请稍等',
				spinner: 'el-icon-loading',
			});
			setTimeout(() => {
				const printTarget = document.querySelectorAll('.pdf-panel');
				if (printTarget.length > 0) {
					const self = this;
					const pdf = new JsPDF({ compress: true, unit: 'pt' });
					Promise.all(
						Array.from(printTarget).map((item) =>
							html2canvas(item, {
								scale: 1,
								scrollX: 0,
								scrollY: -window.scrollY,
							}),
						),
					).then((canvasList) => {
						for (let i = 0; i < canvasList.length; i++) {
							const canvas = canvasList[i];
							const contentWidth = canvas.width;
							const contentHeight = canvas.height;
							const imgWidth = 555.28;
							const imgHeight = (imgWidth / contentWidth) * contentHeight;
							const pageData = canvas.toDataURL('image/png', 1);

							if (i !== 0) pdf.addPage();
							pdf.addImage(pageData, 'png', 20, 20, imgWidth, imgHeight);

							if (i === printTarget.length - 1) {
								const time = dayjs().format('YYYYMMDD');
								const fileName = `${self.memberDetail.name}${time}一日标准健康计划.pdf`;
								const formData = new FormData();
								formData.append('photo', pdf.output('blob'));
								formData.append('name', fileName);
								memberService.uploadReport(formData).then((data) => {
									memberService
										.addReport({
											member_id: self.memberId,
											type: 5,
											bgzl: 16,
											check_date: self.today,
											photo_id: data.id,
											bgms: '出具报告自动上传',
										})
										.then(() => {
											self.$Message.success('转存成功');
										})
										.catch(() => {
											self.$Message.success('转存失败，请手动上传');
										});
								});
								pdf.save(fileName);
								self.$nextTick(() => {
									loading.close();
								});
							}
						}
					});
				} else {
					this.$Message.warning('无法生成PDF，请检查报告是否存在');
				}
			}, 0);
		},
		savePlan() {
			serviceRecordService
				.addDayPlan({
					member_id: this.memberId,
					data: JSON.stringify(this.historyData),
				})
				.then(() => {
					this.$Message.success('保存成功');
					this.planModal = false;
				});
		},
		handleTypeChange(value, item) {
			item.mc = '';
			item.syff = '';
			item.nameOptions = this.planDetail.filter((i) => i.typeName === value);
		},
		handleNameChange(value, item) {
			item.syff = '';
			if (item.mc) {
				item.methodOptions = item.nameOptions.find((i) => i.name === value).fangfa;
			}
		},
		addPlanRow(index) {
			this.historyData.splice(index + 1, 0, {
				sjd: '',
				lx: '',
				mc: '',
				syff: '',
				bz: '',
			});
		},
		removePlanRow(index) {
			if (this.historyData.length === 1) {
				this.$Message.error('最少保留一条数据');
				return;
			}

			this.historyData.splice(index, 1);
		},
		getPDFPlanData() {
			serviceRecordService.getPlan({ member_id: this.memberId }).then((data) => {
				this.pdfPlanData = data.facs;
			});
		},
		generatePDF() {
			this.showPDF = true;
			this.getPDFPlanData();
		},
		replaceBreak(val) {
			if (val) return val.replace(/\n/g, '<br>');
			else return '';
		},
	},
};
</script>

<style lang="less" scoped>
.service-panel {
  margin-top: 20px;
}
.card {
  margin: 0 20px 20px;
  &-title {
    background-color: #507fe0;
    font-size: 16px;
    color: #fff;
    padding: 5px 10px;
    display: inline-block;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    &_status {
      background-color: #fff;
      border: 1px #e9e9e9 solid;
      border-bottom: none;
    }
  }
  &-body {
    border: 1px #e9e9e9 solid;
    padding: 10px;
  }
  &-select {
    width: 50%;
  }
  &-textarea_left {
    margin-top: 10px;
  }
}
.panel {
  margin: 0 10px 20px;
  &-title {
    font-weight: 700;
    margin-bottom: 10px;
  }
}
.record-list {
  margin: 20px;
}
.list-total {
  color: #999;
  padding-right: 20px;
  text-align: right;
}
.flex-panel {
  display: flex;
}
.flex-label {
  margin-right: 10px;
  height: 32px;
  line-height: 32px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb5 {
  margin-bottom: 5px;
}
.plan-history {
  margin-bottom: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  .plan-history-title {
    font-size: 16px;
  }
  .plan-history-content {
    border: 1px #c8c8c8 solid;
    cursor: pointer;
    padding: 5px 10px;
    + .plan-history-content {
      margin-left: 10px;
    }
  }
}
.plan-table {
  table-layout: fixed;
  width: 100%;
  tr {
    border-bottom: 1px #eee solid;
  }
  th, td {
    padding: 5px;
    vertical-align: top;
  }
}
.pdf-title {
  color: #fff;
  font-size: 20px;
  background-color: #045680;
  padding: 10px;
  width: 200px;
  margin: 0 auto 15px;
  text-align: center;
}
.pdf-profile {
  border-top: 1px #045680 solid;
  color: #045680;
  padding-top: 20px;
  font-size: 18px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}
.pdf-desc {
  font-size: 16px;
  margin-bottom: 15px;
  font-style: italic;
}
.pdf-table {
  width: 100%;
  font-size: 18px;
  margin-bottom: 15px;
  th {
    background-color: #045680;
    color: #fff;
  }
  th, td {
    border: 1px #045680 solid;
    padding: 5px 10px;
    line-height: 1.2;
  }
}
.pdf-subtitle {
  width: 100px;
  background-color: #045680;
  color: #fff;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 16px;
  text-align: center;
}
.pdf-content {
  border: 1px #045680 solid;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 1.5;
}
.pdf-footer {
  color: #045680;
  font-size: 18px;
  display: flex;
  justify-content: space-around;
}
</style>
