<template>
  <div class="personal-data-contain">
    <div class="personal-box">
      <div class="personal-search">
        <Button type="info" class="push-button" @click="goToBehavior">ABO行为分型问卷</Button>
        <Button type="warning" class="push-button" @click="addMonitorInfo">添加监测数据</Button>
        <Button type="success" class="push-button" @click="goToQuestion">问题反馈</Button>
      </div>
      <advisory-record></advisory-record>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
import AdvisoryRecord from './advisory-record';

export default {
	components: {
		AdvisoryRecord,
	},
	data() {
		return {
			tBody: {},
			bodyId: '',
			height: {},
			heightId: '',
			blood: {},
			bloodId: '',
			sugar: {},
			sugarId: '',
			grip: {},
			gripId: '',
			capacity: {},
			capacityId: '',
			sports: {},
			sportsId: '',
			meal: {},
			mealId: '',
			train: {},
			trainId: '',
			test_date: '',
			compare: {
				weight: {
					high: '',
					low: '',
				},
				wastline: {
					high: '',
					low: '',
				},
				systolic: {
					high: '',
					low: '',
				},
				heart: {
					high: '',
					low: '',
				},
				diastolic: {
					high: '',
					low: '',
				},
				body_fat: {
					high: '',
					low: '',
				},
				lactone: {
					high: '',
					low: '',
				},
				body_water: {
					high: '',
					low: '',
				},
				bmc: {
					high: '',
					low: '',
				},
				basic: {
					high: '',
					low: '',
				},
				musculi_skeleti: {
					high: '',
					low: '',
				},
				empty: {
					high: '',
					low: '',
				},
				left_grip: {
					high: '',
					low: '',
				},
				right_grip: {
					high: '',
					low: '',
				},
				capacity: {
					high: '',
					low: '',
				},
				build: {
					high: '',
					low: '',
				},
			},
			score: '',
			check: [],
			sick: [],
			drug: {},
			father: [],
			mother: [],
			planRecord: {},
			problemRecord: {},
			servicesRecord: {},
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
		nowDate() {
			let d = new Date();
			return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
		},
		handleCompareWeight() {
			return {
				color1: this.height.weight > this.compare.weight.high,
				color2: this.height.weight < this.compare.weight.low,
			};
		},
		handleCompareWastline() {
			return {
				color1: this.height.wastline > this.compare.wastline.high,
				color2: this.height.wastline < this.compare.wastline.low,
			};
		},
		handleSystolic() {
			return {
				color1: this.blood.systolic > this.compare.systolic.high,
				color2: this.blood.systolic < this.compare.systolic.low,
			};
		},
		handleHeart() {
			return {
				color1: this.blood.heart > this.compare.heart.high,
				color2: this.blood.heart < this.compare.heart.low,
			};
		},
		handleDiastolic() {
			return {
				color1: this.blood.diastolic > this.compare.diastolic.high,
				color2: this.blood.diastolic < this.compare.diastolic.low,
			};
		},
		handleFat() {
			return {
				color1: this.tBody.body_fat > this.compare.body_fat.high,
				color2: this.tBody.body_fat < this.compare.body_fat.low,
			};
		},
		handleLactone() {
			return {
				color1: this.tBody.lactone > this.compare.lactone.high,
				color2: this.tBody.lactone < this.compare.lactone.low,
			};
		},
		handleWater() {
			return {
				color1: this.tBody.body_water > this.compare.body_water.high,
				color2: this.tBody.body_water < this.compare.body_water.low,
			};
		},
		handleBMC() {
			return {
				color1: this.tBody.bmc > this.compare.bmc.high,
				color2: this.tBody.bmc < this.compare.bmc.low,
			};
		},
		handleBasic() {
			return {
				color1: this.tBody.basic > this.compare.basic.high,
				color2: this.tBody.basic < this.compare.basic.low,
			};
		},
		handleMusculi() {
			return {
				color1: this.tBody.musculi_skeleti > this.compare.musculi_skeleti.high,
				color2: this.tBody.musculi_skeleti < this.compare.musculi_skeleti.low,
			};
		},
		handleEmpty() {
			return {
				color1: this.sugar.empty > this.compare.empty.high,
				color2: this.sugar.empty < this.compare.empty.low,
			};
		},
		handleLeft() {
			return {
				color1: this.grip.left_grip > this.compare.left_grip.high,
				color2: this.grip.left_grip < this.compare.left_grip.low,
			};
		},
		handleRight() {
			return {
				color1: this.grip.right_grip > this.compare.right_grip.high,
				color2: this.grip.right_grip < this.compare.right_grip.low,
			};
		},
		handleValue() {
			return {
				color1: this.capacity.value > this.compare.capacity.high,
				color2: this.capacity.value < this.compare.capacity.low,
			};
		},
		handleBuild() {
			return {
				color1: this.tBody.bmi > this.compare.build.high,
				color2: this.tBody.bmi < this.compare.build.low,
			};
		},
	},
	methods: {
		goToBehavior() {
			this.$router.push({ name: 'newBehavior' });
		},
		goToQuestion() {
			this.$router.push({ name: 'newQuestion' });
		},
		handleInit() {
			let params = {
				member_id: this.memberId,
				time: this.test_date,
			};
			MemberService.total(params).then((data) => {
				this.score = data.score;
				this.height = data.height;
				this.heightId = data.height.id;
				this.tBody = data.body;
				this.bodyId = data.body.id;
				this.blood = data.blood;
				this.bloodId = data.blood.id;
				this.sugar = data.sugar;
				this.sugarId = data.sugar.id;
				this.grip = data.grip;
				this.gripId = data.grip.id;
				this.capacity = data.capacity;
				this.capacityId = data.capacity.id;
				this.sports = data.sports;
				this.sportsId = data.sports.id;
				this.meal = data.meal;
				this.mealId = data.meal.id;
				this.train = data.train;
				this.trainId = data.train.id;
				this.compare = data.compare;
				this.check = data.check;
				this.sick = data.sick;
				this.drug = data.drug;
				if (data.father) {
					this.father = data.father;
				} else {
					this.father = [];
				}
				if (data.mother) {
					this.mother = data.mother;
				} else {
					this.mother = [];
				}
				this.planRecord = data.plan;
				this.problemRecord = data.problem;
				this.servicesRecord = data.services;
			});
		},
		addMonitorInfo() {
			this.$router.push({
				path: `/base/member/detail/${this.memberId}/new-monitor`,
			});
		},
	},
	created() {
		this.test_date = this.nowDate;
		this.handleInit();
	},
};
</script>
<style lang="css" scoped>
.color1 {
  color: #f8645c;
}
.color2 {
  color: #78be5f;
}
.personal-box {
  overflow: hidden;
  background: #fff;
}
.personal-data-contain .personal-check-data {
  position: relative;
  width: 100%;
  height: 580px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 30px;
}
.personal-data-contain .personal-push {
  text-align: right;
  margin-top: 15px;
}
.personal-data-contain .personal-search {
  padding: 28px 40px 50px 0;
  text-align: center;
  margin: 0 auto;
}
.personal-data-contain .push-button {
  float: right;
  margin-left: 10px;
}
.personal-data-contain .ivu-input {
  background: #f1f2f4;
}
.personal-rank,
.personal-date {
  width: 470px;
  text-align: center;
  display: inline-block;
}
.personal-data-contain .personal-rank {
  height: 64px;
  border: 1px dashed #00cb84;
  color: #999;
  font-size: 20px;
  margin-bottom: 40px;
  margin-left: 18px;
}
.personal-data-contain .rank-text {
  color: #00cb84;
  font-size: 42px;
  padding-right: 8px;
}
.personal-data-contain .personal-data-list {
  padding-bottom: 52px;
  margin-bottom: 15px;
  color: #666;
}
.personal-data-contain .person-data span {
  padding-left: 10px;
}
.personal-data-contain .title {
  color: #1fcb78;
  padding-left: 15px;
}
.personal-data-contain .data-img-box {
  position: absolute;
}
.personal-data-contain .person-data {
  position: absolute;
  width: 480px;
}
.personal-data-contain .primary-data {
  top: 0;
  left: 540px;
}
.personal-data-contain .person-data a {
  color: #333;
  display: block;
}
.personal-data-contain .person-data p {
  line-height: 25px;
}
.personal-data-contain .blood-data {
  top: 50px;
  left: 198px;
}
.personal-data-contain .new-element {
  top: 0px;
  left: 1005px;
}
.personal-data-contain .blood-glucose {
  top: 179px;
  left: 235px;
}
.personal-data-contain .grip-data {
  left: 1077px;
  top: 179px;
}
.personal-data-contain .vital-capacity {
  top: 315px;
  left: 220px;
}
.personal-data-contain .play-records {
  top: 280px;
  left: 1060px;
}
.personal-data-contain .bite-records {
  bottom: 15px;
  left: 287px;
}
.personal-data-contain .result-records {
  bottom: 15px;
  left: 940px;
}
</style>
